import { defineComponent, toRef } from 'vue';
import { clone } from 'lodash-es';
import { globalState } from '@/globalStore';
// import { api } from '@/composition/api'
import Topbar from '@/components/ui/Topbar.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import TableHead from '@/components/datatable/TableHead.vue';
import TableRow from '@/components/datatable/TableRow.vue';
import TablePagination from '@/components/datatable/TablePagination.vue';
import TableSearch from '@/components/datatable/TableSearch.vue';
import TableFilter from '@/components/datatable/TableFilter.vue';
import TableExport from '@/components/datatable/TableExport.vue';
import ThItem from '@/components/datatable/ThItem.vue';
import TdItem from '@/components/datatable/TdItem.vue';
import ThOrderingIcon from '@/components/datatable/ThOrderingIcon.vue';
import TIcon from '@/components/datatable/TIcon.vue';
import { responsiveDate } from '@/components/datatable/contrib';
import { api } from '@/composition/api';
export default defineComponent({
    components: {
        Topbar,
        DataTable,
        TableHead,
        ThItem,
        TdItem,
        TableRow,
        TablePagination,
        TableSearch,
        ThOrderingIcon,
        TableFilter,
        TableExport,
        TIcon,
    },
    setup() {
        const isInitialized = toRef(globalState.users, 'initialized');
        const userApi = clone(api);
        if (!isInitialized.value) {
            // const resp = await api.get('/api/panopticon/users/')
            // globalState.users.data = resp.data
            globalState.users.initialized = true;
            console.log('users initialized!');
        }
        userApi.defaults.baseURL = process.env.VUE_APP_API_HOST + '/api/panopticon';
        const remoteConfig = {
            mode: 'remote',
            axiosInstance: userApi,
            dataModel: 'users',
            itemsPerPage: 15,
            searchFields: ['name', 'username'],
            filters: [
                {
                    property: 'isVip',
                    type: 'boolean',
                },
            ],
        };
        const exportFn = (data) => {
            let output = '';
            for (const obj of data) {
                output += obj.email + ', ';
            }
            return output;
        };
        return {
            isInitialized,
            users: globalState.users.data,
            api: userApi,
            remoteConfig,
            responsiveDate,
            exportFn,
        };
    },
});
